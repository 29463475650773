<template>
    <div class="schoolData">
        <!-- 学期筛选2.8新增 -->
        <div class="termBox" v-if="true">
            <el-select v-model="termValue" placeholder="请选择" @change="termChange" size='small'>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="class_nav flex-align-between" v-if="$route.query.role == 3">
            <div class="navigation nav_size flex-align-center" :class="replace_index == index && !testBtn ? 'home_pageB' : 'home_pageA'"
                v-for="(item, index) in nav_list" :key="index" @click="nav_chang(index)">{{ item }}</div>
            <div class="navigation nav_size flex-align-center" :class="testBtn ? 'home_pageB' : 'home_pageA'"
                @click="gotestBtn">测评报告</div>
        </div>
        <div class="flex-align-between" v-if="replace_index == 0">
            <div class="leftSide flex-column-between">
                <div class="up flex-align-between">
                    <div class="graph1 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">整体数据</span>
                            </div>
                            <div class="substance flex-column-between">
                                <div class="average_Sta">
                                    <div class="whole_double flex-column">
                                        <div class="flex-align-between">
                                            <div class="whole_double_son whole_double_img1">
                                                教师参与率<div class="numbox whole_three_num">{{ teaJoinRate }}</div>%
                                            </div>
                                            <div class="whole_double_son whole_double_img2">
                                                班级参与率<div class="numbox whole_three_num">{{ classJoinRate }}</div> %
                                            </div>
                                        </div>
                                        <div class="flex-align-between">
                                            <div class="whole_double_son whole_double_img3">
                                                上课总数<div class="numbox whole_three_num">{{ teaTotalTake }}</div> 节
                                            </div>
                                            <div class="whole_double_son whole_double_img4">
                                                测评总人次<div class="numbox whole_three_num">{{ cpAllNum }}</div> 次
                                            </div>
                                        </div>
                                    </div>
                                    <div class="whole_three flex-column">
                                        <div class="flex-align-between">
                                            <div class="whole_three_son whole_three_img1">
                                                班级总数
                                                <div class="numbox whole_three_num">{{ overallData.classTotalNum || 0 }}
                                                </div>
                                                个
                                            </div>
                                            <div class="whole_three_son whole_three_img2">
                                                教师总数
                                                <div class="numbox whole_three_num">{{ overallData.teaTotalNum || 0 }}</div>
                                                人
                                            </div>
                                            <div class="whole_three_son whole_three_img3">
                                                学生总数
                                                <div class="numbox whole_three_num">{{ overallData.stuTotalNum || 0 }}</div>
                                                人
                                            </div>
                                        </div>
                                        <div class="flex-align-between">
                                            <div class="whole_three_son whole_three_img4">
                                                应用班级
                                                <div class="numbox whole_three_num">{{ overallData.applyClassNum || 0 }}
                                                </div>
                                                个
                                            </div>
                                            <div class="whole_three_son whole_three_img5">
                                                参与教师数
                                                <div class="numbox whole_three_num">{{ overallData.joinTeaNum || 0 }}</div>
                                                人
                                            </div>
                                            <div class="whole_three_son whole_three_img6">
                                                学习课程
                                                <div class="numbox whole_three_num">{{ overallData.studyCoursesNum || 0 }}
                                                </div>
                                                节
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                    <div class="graph2 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">课堂应用教学统计</span>
                            </div>
                            <div class="substance">
                                <div class="interaction_Sta" id="interaction" v-if="interaction_show"></div>
                                <div class="interaction_Sta flex-align-center" v-else>
                                    <placeholderScreen></placeholderScreen>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                </div>
                <div class="middle flex-align-between">
                    <div class="graph3 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">各年级学生作品数对比</span>
                            </div>
                            <div class="substance">
                                <div class="contrast_Sta_box flex-align-center">
                                    <div class="contrast_Sta" id="contrast" v-if="contrast_show"></div>
                                    <placeholderScreen v-else></placeholderScreen>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                    <div class="graph4 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">本校测评等级分布</span>
                                <div class="flex-align-between">
                                    <div class="gradeselect">
                                        <el-select v-model="testgradevalue" placeholder="请选择" @change="testgradeChange"
                                            size='small'>
                                            <el-option v-for="item in testgrade" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <span class="gotest" @click="gotest">查看详细报告 ></span>
                                </div>
                            </div>
                            <div class="substance">
                                <template>
                                    <div class="table" v-if="Evaluationgradeshow1">
                                        <div class="schyy_head">
                                            <div class="th1 flex-align-center">等级</div>
                                            <div class="th2 flex-align-center">优秀+</div>
                                            <div class="th3 flex-align-center">优秀</div>
                                            <div class="th4 flex-align-center">良好</div>
                                            <div class="th5 flex-align-center">待提升</div>
                                        </div>
                                        <div class="schyy_list">
                                            <div class="schyy_list_son" v-for="(item, index) in Evaluationgrade"
                                                :key="index">
                                                <div class="th1">{{ item.date }}</div>
                                                <div class="flex-align-center">{{ item.level1 }}
                                                </div>
                                                <div class="flex-align-center">{{ item.level2 }}
                                                </div>
                                                <div class="flex-align-center">{{ item.level3 }}
                                                </div>
                                                <div class="flex-align-center">{{ item.level4 }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table_ber flex-align-center" v-else>
                                        <placeholderScreen></placeholderScreen>
                                    </div>
                                </template>
                                <div class="graphbox">
                                    <div class="graph" id="grade_graph" v-if="Evaluationgradeshow2"></div>
                                    <div class="graph flex-align-center" v-else>
                                        <placeholderScreen></placeholderScreen>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                </div>
                <div class="below flex-align-between">
                    <div class="graph5 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">教师上课排行</span>
                            </div>
                            <div class="substance flex-align-between">
                                <div class="tea_par_statistics" v-if="participationData.length != 0">
                                    <vue-seamless-scroll :data="participationData" :class-option="optionSingleHeightTime"
                                        class="participation-warp">
                                        <ul>
                                            <li v-for="(item, index) in participationData" :key="index" class="flex-layout">
                                                <div class="serial"
                                                    :class="index == 0 ? 'Top1' : index == 1 ? 'Top2' : index == 2 ? 'Top3' : ''">
                                                    {{
                                                        index + 1 }}</div>
                                                <div class="name">教师：<span>{{ item.name }}</span> </div>
                                                <div class="frequency">累计上课：<span>{{ item.num }}节</span></div>
                                            </li>
                                        </ul>
                                    </vue-seamless-scroll>

                                </div>
                                <div class="tea_par_statistics flex-align-center" v-else>
                                    <placeholderScreen></placeholderScreen>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                    <div class="graph6 frame">
                        <div class="basicData flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">各年级应用统计</span>
                            </div>
                            <div class="substance" v-if="Dbarvalue.length != 0">
                                <Dbar :Dbarname="Dbarname" :Dbarvalue="Dbarvalue" :DbarmaxValue="DbarmaxValue">
                                </Dbar>
                            </div>
                            <div class="placeholder flex-align-center" v-else>
                                <placeholderScreen></placeholderScreen>
                            </div>
                        </div>
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </div>
            <div class="rightSide flex-column-between">
                <div class="graph7 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">班级排行</span>
                        </div>
                        <div class="substance" v-if="listData.length != 0">
                            <!-- <vue-seamless-scroll :data="listData" :class-option="optionSingleHeightTime" class="seamless-warp"> -->
                            <ul class="item">
                                <li class="flex-align" v-for="(item, index) in listData" :key="index">
                                    <div class="ranking" v-if="index < 3">
                                        <img :src="listImg[index]" />
                                    </div>
                                    <div class="placeholder" v-else></div>
                                    <div class="rankingData flex-align-between">
                                        <!-- https://apitest.lezhireading.com -->
                                        <img :src="'https://yunapi.cnuzi.com/uploads/' + item.teacherAvatar"
                                            v-if="item.teacherAvatar" />
                                        <img src="../../assets/datascreen/tea-woman.png" v-else />
                                        <div class="flex-column-between">
                                            <div class="flex-align">
                                                <span>{{ item.className }}</span>
                                                <!-- <div class="teacherList">
                                                <span v-for="(i,index) in item.teacherNameList" :key="index"> {{i}}</span>
                                            </div> -->
                                                <div class="examine"
                                                    @click="goclass(item.classId, item.orgId, item.className)">
                                                    查看
                                                </div>
                                            </div>
                                            <div class="mes wes-2" v-if="true">
                                                教师：
                                                <span v-for="(i, index) in item.teacherNameList.slice(0, 3)" :key="index">
                                                    {{ i
                                                    }}</span>
                                                <span v-if="item.teacherNameList.length > 3">等</span>
                                                <!-- <span>教师：{{ item.teacherName }} </span> -->
                                                <!-- <span>累计上课：{{ Math.ceil(item.learnLength / 60)}}节 -->
                                                <span class="mesl2">累计上课：{{ item.lessonCount }}节
                                                </span>
                                                <span>学生作品：{{ item.countWork }}幅</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- </vue-seamless-scroll> -->
                        </div>
                        <div class="substance flex-align-center" v-else>
                            <placeholderScreen></placeholderScreen>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
                <div class="graph8 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">作品实时动态（含师、生作品）</span>
                        </div>
                        <div class="substance" v-if="wordsDatas.length != 0">
                            <wordsRoll :wordsData="wordsDatas" ref="child" @fatherMethod="fatherMethod">
                            </wordsRoll>
                        </div>
                        <div class="substance flex-align-center" v-else>
                            <placeholderScreen></placeholderScreen>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
            </div>
            <div class="appraisalbox">
                <el-dialog :visible.sync="centerDialogVisible" :modal-append-to-body="false" :append-to-body="false"
                    :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" center>
                    <appraisal :word="dialog_words" ref="appraisal"></appraisal>
                    <img class="close" src="../../assets/datascreen/close.png" @click="close" />
                </el-dialog>
            </div>
        </div>
        <schoolData_stuUse v-if="replace_index == 2" :termKey_value="termValue" :schoolId="$route.query.bindId"
            ref="myChild_stuUse">
        </schoolData_stuUse>
        <schoolData_teaUse v-if="replace_index == 1" :termKey_value="termValue" :schoolId="$route.query.bindId"
            :options="options" ref="myChild_teaUse">
        </schoolData_teaUse>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import wordsRoll from "../../Common_components/wordRoll/wordsRoll";
import appraisal from "../../Common_components/appraisals/appraisal";
import schoolData_stuUse from "../DataScreen/schoolData_stuUse.vue";
import schoolData_teaUse from "../DataScreen/schoolData_teaUse.vue";
import placeholderScreen from "./placeholderBox/placeholderScreen.vue";
import Dbar from './prvncndctyAssembly/3dbar.vue';
export default {
    components: {
        vueSeamlessScroll,
        wordsRoll,
        appraisal,
        schoolData_stuUse,
        schoolData_teaUse,
        placeholderScreen,
        Dbar
    },
    data() {
        return {
            wordsDatas: [],
            // 放大图片
            dialog_words: [],
            centerDialogVisible: false,
            // 各年级学生作品数对比
            comparison_name: [],
            comparison_data: [],
            submit_Date: [],
            listData: [],
            listImg: {
                0: require("../../assets/datascreen/ranking1.png"),
                1: require("../../assets/datascreen/ranking2.png"),
                2: require("../../assets/datascreen/ranking3.png"),
            },
            bindId: '',
            orgId: '',
            termValue: '',
            options: [],
            //教师上课排行
            participationData: [],
            //学生应用
            nav_list: ['首页', '教师应用', '学生应用'],
            testBtn:false,
            replace_index: 0,
            //各年级学生作品对比show
            contrast_show: '',
            //课堂应用教学统计
            interaction_show: '',
            //整体数据
            overallData: '',
            teaJoinRate: 0,
            classJoinRate: 0,
            teaTotalTake: 0,
            cpAllNum: 0,
            //各年级应用统计
            Dbarname: [],
            Dbarvalue: [],
            DbarmaxValue: [],
            Evaluationgrade: [
                {
                    date: "人数",
                    level1: 0,
                    level2: 0,
                    level3: 0,
                    level4: 0,
                },
                {
                    date: "占比",
                    level1: '0%',
                    level2: '0%',
                    level3: '0%',
                    level4: '0%',
                },
            ],
            Evaluationgradeshow1: '',
            Evaluationgradeshow2: '',
            testgrade: [{
                value: 1,
                label: '前测'
            }, {
                value: 2,
                label: '后测'
            }, {
                value: 4,
                label: '月测'
            },
            {
                value: 5,
                label: '周测'
            }
        ],
            testgradevalue: 1,
        };
    },
    computed: {
        optionSingleHeightTime() {
            return {
                step: 0.5,
                openWatch: true,
            };
        },
    },
    //方法
    methods: {
        //学校大屏首页整体数据
        async getoverallData() {
            this.overallData = '';
            this.teaJoinRate = 0;
            this.classJoinRate = 0;
            this.teaTotalTake = 0;
            this.cpAllNum = 0;
            let data = {
                schoolId: this.$route.query.schoolId || this.$route.query.bindId,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.getoverallData(data);
            if (resData.data == undefined) return false;
            this.overallData = resData.data;
            this.teaJoinRate = resData.data.teaJoinRate;
            this.classJoinRate = resData.data.classJoinRate;
            this.teaTotalTake = resData.data.teaTotalTake;
            this.cpAllNum = resData.data.cpAllNum;
        },
        //各年级学生作品数对比
        async queryChildrenData() {
            this.comparison_name = [];
            this.comparison_data = [];
            let data = {
                orgId: this.orgId,
                chartId: 3,
                attId: 5,
                termKey: this.termValue,
                excludeEmptyTeacherClass: true
            };
            let that = this;
            let resData = await this.$Api.DataScreen.queryChildrenData(data);
            let value = resData.data;

            if (value.length != 0) {
                for (let i = 0; i < value.length; i++) {
                    that.comparison_name.push(value[i].name);
                    that.comparison_data.push(value[i].count);
                }
                this.contrast_show = !this.comparison_data.every(element => element === 0);
                if (this.contrast_show) {
                    this.$nextTick(() => {
                        that.drawContrast();
                    });
                }
            } else {
                this.contrast_show = false;
            }

        },
        // 各年级学生作品数对比
        drawContrast() {
            this.$echarts.init(document.getElementById("contrast")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("contrast"));
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                        // color: "#fff",
                    },
                },
                grid: {
                    top: this.graphicRatio(10),
                    left: "16%",
                    right: "12%",
                    bottom: "15%",
                },
                xAxis: {
                    type: "value",
                    minInterval: 1,
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12)
                    },
                },
                yAxis: {
                    type: "category",
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12)
                    },
                    axisLine: {
                        show: false, //隐藏y轴
                    },
                    axisTick: {
                        show: false, //刻度线
                    },
                    // data: ['1年级', '2年级', '3年级', '4年级', '5年级', '6年级']
                    data: this.comparison_name,
                },
                series: [{
                    // data: [120, 200, 150, 80, 70, 110],
                    data: this.comparison_data,
                    type: "bar",
                    barCategoryGap: "50%",
                    itemStyle: {
                        borderRadius: [this.graphicRatio(15), this.graphicRatio(15), this.graphicRatio(15), this.graphicRatio(15)],

                        color: function (params) {
                            return new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: "rgba(46, 93, 199, 1)",
                            },
                            {
                                offset: 1,
                                color: "rgba(0, 204, 255, 1)",
                            },
                            ]);
                        },
                    },
                    backgroundStyle: {
                        color: "rgba(180, 180, 180, 0.2)",
                    },
                },],
            }),
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
        },
        //=各年级学生作品数对比求和
        countTotal(arr, keyName) {
            let $total = 0;
            $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
                return currentValue[keyName] ? total + currentValue[keyName] : total;
            }, 0);
            return $total;
        },
        //教师上课排行
        async queryTeacher_class() {
            let data = {
                schoolId: this.$route.query.schoolId || this.$route.query.bindId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.queryTeacher_class(data);
            this.participationData = resData.data;
        },
        //查询课堂互动教学统计 --- 没有数据
        async queryTeachStatistics() {
            let that = this;
            let data = {
                orgId: this.orgId,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.queryTeachStatistics(data);
            if (JSON.stringify(resData.data) != '{}') {
                // 找出长度最长的作为x轴
                let maxLength = 0;
                let maxPropName = '';
                for (let propName in resData.data) {
                    if (resData.data[propName].length > maxLength) {
                        maxLength = resData.data[propName].length;
                        maxPropName = propName
                    }
                };
                // 计算折线图x轴坐标
                const totalArr = [];

                Object.keys(resData.data).forEach(key => {
                    totalArr.push(...resData.data[key])
                });
                const newTotalArr = [];
                resData.data[maxPropName].map(item => {
                    newTotalArr.push(item.name)
                })
                // 计算各年级数据
                let series = [];
                Object.keys(resData.data).forEach(key => {
                    let obj = {
                        name: key,
                        type: 'line',
                        lineStyle: {
                            width: this.graphicRatio(1)
                        },
                        // stack: "Total",
                        data: []
                    }
                    newTotalArr.map(item => {
                        var temp = 0;
                        resData.data[key].map(items => {
                            if (item == items.name) {
                                temp = items.count
                            }
                        })
                        obj.data.push(temp)
                    })
                    series.push(obj)
                })
                series = series.filter(item => {
                    return item.name != "entity"
                })
                this.interaction_show = true;
                this.$nextTick(() => {
                    that.drawInteraction(newTotalArr, series);
                });

            } else {
                this.interaction_show = false;
                this.$echarts.init(document.getElementById("interaction")).dispose();
                // this.drawInteraction([], []);
            }

        },
        // 课堂应用教学统计-折线图
        drawInteraction(xData, series) {
            this.$echarts.init(document.getElementById("interaction")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("interaction"));
            myChart.setOption({
                dataZoom: [{
                    type: "inside",
                    show: true,
                    zoomLock: true,
                    start: 0,
                    end: 100
                }],
                tooltip: {
                    trigger: "axis",
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                        // color: "#fff",
                    },
                },
                legend: {
                    right: this.graphicRatio(5),
                    top: "top",
                    itemGap: this.graphicRatio(15),
                    orient: "vertical",
                    itemWidth: this.graphicRatio(20),
                    itemHeight: this.graphicRatio(12),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                        color: "#fff",
                    },
                },
                grid: {
                    containLabel: true,
                    top: "20%",
                    left: this.graphicRatio(25),
                    right: "15%",
                    bottom: this.graphicRatio(15),
                },
                xAxis: {
                    type: "category",
                    name: "时间", //x轴名称
                    axisLine: {
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                        interval: 0,
                        rotate: 30,
                        fontSize: this.graphicRatio(12)
                    },
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: xData,
                    nameTextStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    // max:7
                    boundaryGap: true
                },
                yAxis: {
                    type: "value",
                    name: "课时",
                    axisLine: {
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12)
                    },
                    splitLine: {
                        show: false,
                    },
                    nameTextStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                color: ["#F0DB3C", "#FA3974", "#269DE3", "#e8543c", "#3dac62", "#ca79e2"],
                series: series,
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //本校测评等级分布
        async getschoolEvalLevel() {
            this.Evaluationgrade = [{
                date: "人数",
                level1: 0,
                level2: 0,
                level3: 0,
                level4: 0,
            },
            {
                date: "占比",
                level1: '0%',
                level2: '0%',
                level3: '0%',
                level4: '0%',
            }];
            let data = {
                schoolId: this.$route.query.schoolId || this.$route.query.bindId,
                termKey: this.termValue,
                examType: this.testgradevalue
                // 试卷类型(1:前测,2:后测,4:月测)
            };
            let resData = await this.$Api.DataScreen.getschoolEvalLevel(data);
            let EvaluationgradeNum = [];
            let proportion = [];
            if (resData.data.length != 0) {
                this.Evaluationgradeshow1 = true;
                this.Evaluationgradeshow2 = true;
                let num = {
                    date: "人数",
                    level1: resData.data[3].num,
                    level2: resData.data[2].num,
                    level3: resData.data[1].num,
                    level4: resData.data[0].num,
                };
                let rate = {
                    date: "占比",
                    level1: resData.data[3].percent + '%',
                    level2: resData.data[2].percent + '%',
                    level3: resData.data[1].percent + '%',
                    level4: resData.data[0].percent + '%',
                };
                this.Evaluationgrade = [num, rate];
                EvaluationgradeNum = [resData.data[3].num, resData.data[2].num, resData.data[1].num, resData.data[0].num];
                proportion = [resData.data[3].percent, resData.data[2].percent, resData.data[1].percent, resData.data[0].percent];
                this.$nextTick(() => {
                    this.pictorialBar_graphics(EvaluationgradeNum, proportion);
                });
            } else {
                this.Evaluationgradeshow1 = false;
                this.Evaluationgradeshow2 = false;
                this.$echarts.init(document.getElementById("grade_graph")).dispose();
            }
        },
        //本校测评等级分布
        pictorialBar_graphics(EvaluationgradeNum, proportion) {
            this.$echarts.init(document.getElementById("grade_graph")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("grade_graph")
            );
            let maxdata = Math.max(...EvaluationgradeNum);
            myChart.setOption({
                grid: {
                    top: '20%',
                    bottom: '12%',
                    right: '22%',
                    left: '22%',
                },
                xAxis: [{
                    type: "category",
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: '#44506b',
                        }
                    },
                    boundaryGap: false,
                    data: ['优秀+', '优秀', '良好', '待提升'],
                }],
                yAxis: [{
                    type: "value",
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: { show: false },
                }],
                series: [{
                    name: "直接访问",
                    type: "bar",
                    barWidth: '1',
                    label: {
                        show: true,
                        position: 'top',
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(12)
                        },
                        formatter: function (params) {
                            return proportion[params.dataIndex] + '%'
                        },
                    },
                    itemStyle: { // 添加这一行来设置柱子样式
                        color: 'transparent' // 示例中使用黑色并设置半透明
                    },
                    data: [maxdata, maxdata, maxdata, maxdata]
                },
                {
                    name: 'hill',
                    type: 'pictorialBar',
                    barCategoryGap: '-120%',
                    symbolClip: true,
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    emphasis: {
                        itemStyle: {
                            opacity: 1
                        }
                    },
                    itemStyle: {
                        color: function (params) {
                            if (params.dataIndex == 0) {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(207, 149, 8, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(207, 149, 8, 0.8)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            } else if (params.dataIndex == 1) {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(5, 194, 129, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(5, 194, 129, 0.8)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            } else if (params.dataIndex == 2) {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(4, 140, 202, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(4, 140, 202, 0.8)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            } else {
                                return {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(186, 71, 100, 0)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(186, 71, 100, 0.8)',
                                        },
                                    ],
                                    global: false, //  缺省为  false
                                }
                            }
                        },
                    },
                    data: EvaluationgradeNum,
                    z: 10
                }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //各年级应用统计
        async getapplyStatByGradeBySchool() {
            this.Dbarname = [];
            this.Dbarvalue = [];
            this.DbarmaxValue = [];
            let data = {
                schoolId: this.$route.query.schoolId || this.$route.query.bindId,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.getapplyStatByGradeBySchool(data);
            if (resData.data.length == 0) return;

            resData.data.forEach(element => {
                this.Dbarname.push(element.indexRank + '年级');
                this.Dbarvalue.push(element.result);
            });
            resData.data.forEach(element => {
                this.DbarmaxValue.push(Math.max(...this.Dbarvalue) + Math.max(...this.Dbarvalue) * 0.2);
            });
        },
        // 班级分布饼图
        drawAverage(data) {
            this.$echarts.init(document.getElementById("average")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("average"));
            myChart.setOption({
                tooltip: {
                    trigger: "item",
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    top: "middle",
                    left: "5%",
                    orient: "vertical",
                    itemGap: this.graphicRatio(25),
                    icon: "rect",
                    itemWidth: this.graphicRatio(12),
                    itemHeight: this.graphicRatio(12),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                        color: "#fff",
                    },
                },
                color: [
                    "#4C83FF",
                    "#FF7391",
                    "#AE74F3",
                    "#60DAAB",
                    "#FFEA68",
                    "#FF8F5F",
                ],
                series: [{
                    name: "班级分布",
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: this.graphicRatio(40),
                            fontWeight: "bold",
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: data,
                },],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //根据组织ID查询班级排名
        async queryClassRankByOrgId() {
            let data = {
                orgId: this.orgId,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.queryClassRankByOrgId(data);
            this.listData = resData.data;
        },
        //查询作品动态列表
        async queryWorkData() {
            this.wordsDatas = [];
            let data = {
                bindId: this.$route.query.bindId,
                level: 5,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.queryWorkData(data);
            if (JSON.stringify(resData.data) == '{}') {
                this.wordsDatas = [];
            } else {
                this.wordsDatas = resData.data;
            }
        },
        // 选择学年
        termChange(value) {
            this.termValue = value;
            if (this.replace_index == 0) {
                this.getOrgInfoid();
            } else if (this.replace_index == 1) {
                this.$refs.myChild_teaUse.receive(this.termValue, this.$route.query.bindId)
            } else if (this.replace_index == 2) {
                this.$refs.myChild_stuUse.receive(this.termValue, this.$route.query.bindId)
            }
        },
        //顶部切换点击事件
        nav_chang(index) {
            this.replace_index = index;
            this.testBtn = false;
            if (this.replace_index == 0) {
                this.getOrgInfoid();
            }
        },
        gotestBtn() {
            this.testBtn = true;
            window.open(`/newreport?type=school&schoolId=${this.$route.query.bindId}&schoolName=${this.$route.query.name}&fromType=multiRole&showType=1&date=${this.termValue}&testType=${this.testgradevalue}&orgId=${this.orgId}`, "_blank");
        },
        //筛选测评报告类型
        testgradeChange(val) {
            this.testgradevalue = val;
            this.getschoolEvalLevel()
        },
        //跳转详情报告
        gotest() {
            window.open(`/newreport?type=school&schoolId=${this.$route.query.bindId}&schoolName=${this.$route.query.name}&fromType=multiRole&showType=1&date=${this.termValue}&testType=${this.testgradevalue}&orgId=${this.orgId}`, "_blank");
        },
        //班级查看跳转
        goclass(classId, orgId, className) {
            let that = this;
            let routeData = this.$router.resolve({
                path: '/dataScreenMain/classData', query: {
                    orgId: orgId, // 组织机构id
                    bindId: classId, //班级id
                    type: "class",
                    role: that.$route.query.role,
                    classname: className,
                    termValue: Number(this.termValue),
                    schoolName: this.$route.query.name,
                    schoolId: this.$route.query.schoolId || this.$route.query.bindId,
                    inside: 0,

                }
            });
            window.open(routeData.href, '_blank');
        },
        //调出弹窗--停止滚动
        fatherMethod(value) {
            this.centerDialogVisible = true;
            this.dialog_words = value;
        },
        //关闭大图
        close() {
            this.centerDialogVisible = false;
            this.$refs.child.magnify_start();
        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    let that = this;
                    res.data.map((item, index) => {
                        that.options.push({
                            value: item.key,
                            label: item.termName
                        });
                    });
                    if (that.$route.query.date) {
                        const index = that.options.findIndex(item => item.value == Number(that.$route.query.date));
                        this.termValue = that.options[index].value;
                    } else {
                        this.termValue = that.options[0].value;
                    }
                    this.getOrgInfoid();
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        requestCollection() {
            //整体数据
            this.getoverallData();
            //各年级学生作品数对比
            this.queryChildrenData();
            // 教师上课排行
            this.queryTeacher_class();
            //查询课堂互动教学统计
            this.queryTeachStatistics();
            //本校测评等级分布
            this.getschoolEvalLevel();
            //各年级应用统计
            this.getapplyStatByGradeBySchool();
            //根据组织ID查询班级排名
            this.queryClassRankByOrgId();
            //查询作品动态列表
            this.queryWorkData();
            //-----------------------
        },
        async getOrgInfoid() {
            let data = {
                bindId: this.$route.query.bindId,
                level: 5,
                termKey: this.termValue
            };
            let resData = await this.$Api.DataScreen.getOrgInfo(data);
            this.orgId = resData.data.id;
            this.requestCollection();
        },

    },
    //生命周期 - 创建完成（访问当前this实例）
    created() { },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.getTermList();
        this.bindId = this.$route.query.bindId;
    },
};
</script>
<style lang="less" scoped>
@import "./less/schoolData.less";
</style>