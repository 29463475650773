<template>
    <div class="dbar" id="dbar"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['Dbarname', 'Dbarvalue', 'DbarmaxValue'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        dbar_graphics() {
            this.$echarts.init(document.getElementById("dbar")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("dbar")
            );
            //颜色
            let ColorText = ['#5cbfe1', '#4164f3', '#814df0', '#e23af5', '#f5a43a', '#fd9898'];
            const Color = [
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(66, 168, 217,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(109, 205, 230,1)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(29, 67, 243,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(67, 102, 243,1)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(90, 47, 215,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(134, 81, 244,1)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(172, 56, 227,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(226, 58, 245,1)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(208, 127, 48,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(245, 164, 58,1)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(254, 114, 145,1)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(253, 153, 152,1)'
                }
                ]),
            ];
            //颜色
            const Color2 = [
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(66, 168, 217,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(109, 205, 230,0.27)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(29, 67, 243,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(67, 102, 243,0.27)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(90, 47, 215,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(134, 81, 244,0.27)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(172, 56, 227,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(226, 58, 245,0.27)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(208, 127, 48,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(245, 164, 58,0.27)'
                }
                ]),
                new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(254, 114, 145,0.27)'
                },
                {
                    offset: 0.8,
                    color: 'rgba(253, 153, 152,0.27)'
                }
                ]),
            ];
            // 绘制左侧面
            const CubeLeft = this.$echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0
                },
                buildPath: function (ctx, shape) {
                    // 会canvas的应该都能看得懂，shape是从custom传入的
                    const xAxisPoint = shape.xAxisPoint
                    const c0 = [shape.x, shape.y]
                    const c1 = [shape.x - 13, shape.y - 13]
                    const c2 = [xAxisPoint[0] - 13, xAxisPoint[1] - 13]
                    const c3 = [xAxisPoint[0], xAxisPoint[1]]
                    ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
                }
            })
            // 绘制右侧面
            const CubeRight = this.$echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0
                },
                buildPath: function (ctx, shape) {
                    const xAxisPoint = shape.xAxisPoint
                    const c1 = [shape.x, shape.y]
                    const c2 = [xAxisPoint[0], xAxisPoint[1]]
                    const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
                    const c4 = [shape.x + 18, shape.y - 9]
                    ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
                }
            })
            // 绘制顶面
            const CubeTop = this.$echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0,
                },
                buildPath: function (ctx, shape) {
                    const c1 = [shape.x, shape.y]
                    const c2 = [shape.x + 18, shape.y - 9]
                    const c3 = [shape.x + 5, shape.y - 22]
                    const c4 = [shape.x - 13, shape.y - 13]
                    ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
                }
            })
            // 注册三个面图形
            this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
            this.$echarts.graphic.registerShape('CubeRight', CubeRight)
            this.$echarts.graphic.registerShape('CubeTop', CubeTop)
            myChart.setOption({
                grid: {
                    top: '20%',
                    right: '50',
                    left: '20',
                    bottom: '5%',
                    containLabel: true,
                },
                xAxis: [{
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#2e76c4",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    data: this.Dbarname,

                }],
                // y轴设置
                yAxis: [
                    {
                        type: 'value',
                        name: '单位：次',
                        min: 0,
                        nameTextStyle: {
                            color: '#fff',
                            // padding: [0, 0, 0, 15],
                            fontSize: this.graphicRatio(12),
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#2e76c4",
                            }
                        },
                        axisLabel: {
                            // textStyle: {
                            color: "#fff",
                            fontSize: this.graphicRatio(12),
                            // }
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    }
                ],
                series: [
                    {
                        type: 'custom',
                        renderItem: function (params, api) {
                            const location = api.coord([api.value(0), api.value(1)])
                            var color1 = Color2[params.dataIndexInside];
                            return {
                                type: 'group',
                                children: [{
                                    type: 'CubeLeft',
                                    shape: {
                                        api,
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        // fill: 'rgba(47,102,192,.27)'
                                        fill: color1
                                    }
                                }, {
                                    type: 'CubeRight',
                                    shape: {
                                        api,
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        // fill: 'rgba(59,128,226,.27)'
                                        fill: color1
                                    }
                                }, {
                                    type: 'CubeTop',
                                    shape: {
                                        api,
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        // fill: 'rgba(72,156,221,.27)'
                                        fill: color1
                                    }
                                }]
                            }
                        },
                        data: this.DbarmaxValue,


                    },
                    {
                        type: 'custom',
                        renderItem: (params, api) => {
                            const location = api.coord([api.value(0), api.value(1)])
                            var color = Color[params.dataIndexInside];
                            return {
                                type: 'group',
                                children: [{
                                    type: 'CubeLeft',
                                    shape: {
                                        api,
                                        xValue: api.value(0),
                                        yValue: api.value(1),
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        fill: color
                                    }
                                }, {
                                    type: 'CubeRight',
                                    shape: {
                                        api,
                                        xValue: api.value(0),
                                        yValue: api.value(1),
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        fill: color
                                    }
                                }, {
                                    type: 'CubeTop',
                                    shape: {
                                        api,
                                        xValue: api.value(0),
                                        yValue: api.value(1),
                                        x: location[0],
                                        y: location[1],
                                        xAxisPoint: api.coord([api.value(0), 0])
                                    },
                                    style: {
                                        fill: color
                                    }
                                }]
                            }
                        },
                        data: this.Dbarvalue,
                    },
                    {
                        type: 'bar',
                        itemStyle: {
                            color: 'transparent',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: this.graphicRatio(12),
                            color: '#fff',
                            // offset: [2, -25]
                            offset: [this.graphicRatio(2), this.graphicRatio(-25)]
                        },
                        data: this.Dbarvalue,
                    }],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.dbar_graphics()
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
.dbar {
    width: 100%;
    height: 100%;
}
</style>