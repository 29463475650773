<template>
    <div class="schoolData_teaUse flex-align-between">
        <div class="layout_L">
            <div class="headline middle_sized flex-align-center">
                本校应用整体分析
            </div>
            <div class="particulars">
                <div class="category_analysis flex-align-around">
                    <div class="Participating_teacher particulars_style">
                        <span>参与教师</span>
                        <span class="special">{{ countTeach }}</span>
                    </div>
                    <div class="participation_rate particulars_style">
                        <span>参与率</span>
                        <span class="special">{{ ratio }}%</span>
                    </div>
                    <div class="Total_attendance particulars_style">
                        <span>上课总数</span>
                        <span class="special">{{ countCourse }}</span>
                    </div>
                </div>
                <div class="type_distribution flex-align-between">
                    <div class="classtype_distribution">
                        <div class="Secondary_heading Version1">
                            <span>本校上课类型分布</span>
                        </div>
                        <div class="graphic_son" id="classtype_distribution" v-if="!classtype_distributio_Show"></div>
                        <PlaceholderScreen v-else></PlaceholderScreen>
                    </div>
                    <div class="adhibition_compare">
                        <div class="Secondary_heading Version2">
                            <span>教师应用环比</span>
                        </div>
                        <div class="graphic_son" id="adhibition_compare" v-if="!adhibition_compare_Show"></div>
                        <PlaceholderScreen v-else></PlaceholderScreen>
                    </div>
                </div>
                <div class="temporal_distribution">
                    <div class="Secondary_heading Version2">
                        <span>应用时间分布</span>
                    </div>
                    <div class="graphic_son" id="temporal_distribution" v-if="!temporal_distribution_Show"></div>
                    <PlaceholderScreen v-else></PlaceholderScreen>
                </div>
            </div>
        </div>
        <div class="layout_R flex-column-between">
            <div class="Curriculum_application">
                <div class="headline large_scale flex-align-center">
                    各年级课程应用情况
                </div>
                <div class="particulars">
                    <div class="distribution_statistics">
                        <div class="Secondary_heading Version2">
                            <span>上课分布统计</span>
                        </div>
                        <div class="graphic_son" id="distribution_statistics" v-if="!distribution_statistics_Show"></div>
                        <PlaceholderScreen v-else></PlaceholderScreen>
                    </div>
                    <div class="application_details">
                        <div class="Secondary_heading Version1">
                            <span>各类课程应用详情</span>
                        </div>
                        <div class="placeholder">
                            <img src="../../assets/datascreen/export.png" alt="" @click="getgradeCourseApplyExcel">
                            <span @click="getgradeCourseApplyExcel"> 导出数据</span>
                        </div>
                        <div class="graphic_son">
                            <div class="table_her flex-align-between">
                                <div class="table_her_space flex-align-center"
                                    v-for="(item, index) in termKey_value == '20231' || termKey_value == '20232' ? course_use_list_old : course_use_list_new"
                                    :key="index">{{ item }}</div>
                            </div>
                            <div class="table_list" v-if="kindsClass_app_details.length != 0">
                                <div class="table_list_space flex-align-between"
                                    v-for="( item, index ) in  kindsClass_app_details " :key="index">
                                    <div>{{ item.typeName }}</div>
                                    <div>{{ item['同步课程'] }}</div>
                                    <div>{{ item['硬笔体系课'] }}</div>
                                    <div v-if="termKey_value == '20231' || termKey_value == '20232'">{{ item['每日一字'] }}
                                    </div>
                                    <div v-else>{{ item['简洁版'] }}</div>
                                    <div>{{ item['软笔体系课'] }}</div>
                                    <div>{{ item.sum }}</div>
                                </div>
                            </div>
                            <PlaceholderScreen v-else></PlaceholderScreen>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Teacher_application">
                <div class="headline mini_type flex-align-center">
                    教师应用详情
                </div>
                <div class="placeholder">
                    <img src="../../assets/datascreen/export.png" alt="" @click="getteacherApplyDetailsExcel">
                    <span @click="getteacherApplyDetailsExcel"> 导出数据</span>
                </div>
                <div class="particulars">
                    <div class="graphic_son">
                        <div class="table_her flex-align-between">
                            <div class="table_her_space flex-align-center"
                                v-for="( item, index ) in termKey_value == '20231' || termKey_value == '20232' ? teacher_use_list_old : teacher_use_list_new "
                                :key="index">{{ item }}</div>
                        </div>
                        <div class="table_list" v-if="tea_use_details.length != 0">
                            <div class="table_list_space  flex-align-between" v-for="( item, index ) in  tea_use_details "
                                :key="index">
                                <div class="text_o">{{ item.userName }}</div>
                                <div>{{ item.className }}</div>
                                <div>{{ item['同步课程'] }}</div>
                                <div v-if="termKey_value == '20231' || termKey_value == '20232'">{{ item['每日一字'] }}</div>
                                <div v-else>{{ item['简洁版'] }}</div>
                                <div>{{ item['硬笔体系课'] }}</div>
                                <div>{{ item['软笔体系课'] }}</div>
                                <div>{{ item['视频投影'] }}</div>
                                <div>{{ item['同步投影作品'] }}</div>
                            </div>
                        </div>
                        <PlaceholderScreen v-else></PlaceholderScreen>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../DataScreen/placeholderBox/placeholderScreen.vue';
export default {
    //import引入组件才能使用
    components: {
        PlaceholderScreen,
    },
    props: ['termKey_value', 'schoolId', 'options'],
    data() {
        return {
            countCourse: 0,
            countTeach: 0,
            ratio: 0,
            course_use_list_old: ['类型名称', '同步课程', '硬笔体系课', '每日一字', '软笔体系课', '合计'],
            course_use_list_new: ['类型名称', '同步课程', '硬笔体系课', '简洁版', '软笔体系课', '合计'],
            teacher_use_list_old: ['教师名称', '班级名称', '同步课程', '每日一字', '硬笔体系课', '软笔体系课', '视频投影', '同步投影作品'],
            teacher_use_list_new: ['教师名称', '班级名称', '同步课程', '简洁版', '硬笔体系课', '软笔体系课', '视频投影', '同步投影作品'],
            termKey: '',
            school_Id: '',
            kindsClass_app_details: [],
            tea_use_details: [],
            classtype_distributio_Show: '',
            adhibition_compare_Show: '',
            temporal_distribution_Show: '',
            distribution_statistics_Show: '',
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //本校应用整体分析
        async getoverallAnalysis() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getoverallAnalysis(data);
            this.countCourse = resData.data.countCourse;
            this.countTeach = resData.data.countTeach;
            this.ratio = resData.data.ratio;
        },
        // 本校上课类型分布
        async gettakeClassTypeDistribution() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.gettakeClassTypeDistribution(data);
            let value1 = Object.keys(resData.data).map(key => (
                {
                    value: resData.data[key],
                    name: key,
                }
            ));
            value1.sort((a, b) => b.value - a.value);

            //判断空数据
            if (this.termKey_value == '20231' || this.termKey_value == '20232') {
                value1 = value1.filter(item => item.name != '简洁版');
            } else {
                value1 = value1.filter(item => item.name != '每日一字');
            }
            let name1 = value1.slice(0, 3);
            let name2 = value1.slice(3, 6);
            this.classtype_distributio_Show = value1.every(item => item.value === 0);
            if (this.classtype_distributio_Show) return;
            this.$nextTick(() => {
                that.classtype_distribution(name1, name2, value1)
            });

        },
        //教师应用环比
        async getteachApplyCountRatio() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getteachApplyCountRatio(data);
            let type1 = resData.data.currentTermDesc;
            let type2 = resData.data.lastTermDesc;
            let currentYearName = Object.keys(resData.data.currentYear[0]);
            let currentYearValue = Object.values(resData.data.currentYear[0]);
            let lastYearName = Object.keys(resData.data.lastYear[0]);
            let lastYearValue = Object.values(resData.data.lastYear[0]);
            //判断空数据
            let state1 = currentYearValue.every(item => item === 0);
            let state2 = lastYearValue.every(item => item === 0);
            this.adhibition_compare_Show = state1 && state2;
            if (this.adhibition_compare_Show) return;
            this.$nextTick(() => {
                that.adhibition_compare(currentYearName, currentYearValue, lastYearName, lastYearValue, type1, type2)
            });
        },
        //本校上课时间分布
        async getschoolTimeDistribution() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getschoolTimeDistribution(data);
            //判断空数据
            let judge = (() => {
                for (let key in resData.data) {
                    if (Array.isArray(resData.data[key])) {
                        if (!resData.data[key].every(item => item === 0)) {
                            return false;
                        }
                    } else {
                        console.error(`Property ${key} is not an array`);
                        return false;
                    }
                }
                return true;
            })();
            this.temporal_distribution_Show = judge;
            if (this.temporal_distribution_Show) return;
            this.$nextTick(() => {
                that.temporal_distribution(resData.data)
            });
        },
        //本校上课分布 (按类型&年级分类)
        async gettakeClassTypeDistributionGroupGrade() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.gettakeClassTypeDistributionGroupGrade(data);
            // console.log('resData', resData);
            //判断空数据
            if (Object.keys(resData.data).length === 0 && resData.data.constructor === Object) {
                this.distribution_statistics_Show = true;
                return;
            }
            this.$nextTick(() => {
                // delete resData.data['软笔体系课']
                delete resData.data['硬笔同步课程']
                that.distribution_statistics(resData.data)
            });
        },
        //各类课程应用详情
        async getdetailsCourseApply() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getdetailsCourseApply(data);
            this.kindsClass_app_details = resData.data;
        },
        //教师应用详情
        async getteacherApplyDetails() {
            let that = this;
            let data = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getteacherApplyDetails(data);
            // this.tea_use_details = resData.data.sort((a, b) => b['同步课程'] - a['同步课程']).filter(item => item.teachName);
            this.tea_use_details = resData.data;
        },
        //各年级课程应用情况Excel导出
        getgradeCourseApplyExcel() {
            let datas = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            this.$Api.DataScreen.getgradeCourseApplyExcel(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '各年级课程应用情况';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xlsx';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //教师应用详情Excel导出
        getteacherApplyDetailsExcel() {
            let datas = {
                schoolId: this.school_Id,
                termKey: this.termKey,
            };
            this.$Api.DataScreen.getteacherApplyDetailsExcel(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '教师应用详情';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xlsx';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //本校上课类型分布
        classtype_distribution(name1, name2, value1) {
            this.$echarts.init(document.getElementById("classtype_distribution")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("classtype_distribution"));
            let color = [
                new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                    [{
                        offset: 0,
                        color: 'rgba(14, 210, 249,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(4, 136, 237,1)'
                    }]), new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                        [{
                            offset: 0,
                            color: 'rgba(6, 112, 233,1)'
                        }, {
                            offset: 1,
                            color: 'rgba(4, 134, 237,1)'
                        }]), new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                            [{
                                offset: 0,
                                color: 'rgba(107, 212, 158,1)'
                            }, {
                                offset: 1,
                                color: 'rgba(143, 240, 191,1)'
                            }]), new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                                [{
                                    offset: 0,
                                    color: 'rgba(226, 226, 114,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(249, 248, 141,1)'
                                }]), new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                                    [{
                                        offset: 0,
                                        color: 'rgba(255, 205, 94,1)'
                                    }, {
                                        offset: 1,
                                        color: 'rgba(255, 184, 62,1)'
                                    }]), new that.$echarts.graphic.LinearGradient(0, 0, 1, 0,
                                        [{
                                            offset: 0,
                                            color: 'rgba(213, 101, 246,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(147, 42, 225,1)'
                                        }])];
            myChart.setOption({
                color: color,
                legend: [
                    {
                        orient: 'horizontal',
                        icon: 'circle',
                        align: 'left',
                        bottom: '0',
                        itemWidth: this.graphicRatio(11),
                        itemHeight: this.graphicRatio(11),
                        y: '8',
                        x: 'center',
                        data: name1,
                        formatter: function (params) {
                            return `{b|${params}} `;
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                            align: 'left',
                            // 文字块背景色，一定要加上，否则对齐不会生效
                            backgroundColor: "transparent",
                            rich: {
                                b: {
                                    fontSize: this.graphicRatio(12),
                                    width: this.graphicRatio(58),

                                },
                            },
                        },
                    },
                    {
                        orient: 'horizontal',
                        icon: 'circle',
                        align: 'left',
                        bottom: '0',
                        itemWidth: this.graphicRatio(11),
                        itemHeight: this.graphicRatio(11),
                        y: '25',
                        x: 'center',
                        data: name2,
                        formatter: function (params) {
                            return `{b|${params}} `;
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: this.graphicRatio(11),
                            align: 'left',
                            // 文字块背景色，一定要加上，否则对齐不会生效
                            backgroundColor: "transparent",
                            rich: {
                                b: {
                                    fontSize: this.graphicRatio(12),
                                    width: this.graphicRatio(58),
                                },
                            },
                        },
                    }
                ],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['32%', '50%'],
                        center: ['50%', '55%'],
                        avoidLabelOverlap: true,
                        label: {
                            color: '#fff',
                            alignTo: 'labelLine',
                            // formatter: '{name|{b}}\n{num|{c}}条 {zb|{d}}%',
                            formatter: '{name|{b}} ：\n{num|{c}} 次',
                            minMargin: this.graphicRatio(5),
                            edgeDistance: 10,
                            lineHeight: this.graphicRatio(18),
                            fontSize: this.graphicRatio(12),
                            rich: {
                                num: {
                                    fontSize: this.graphicRatio(12),
                                    align:'right'
                                },
                                zb: {
                                    fontSize: this.graphicRatio(12),
                                    align:'right'
                                }
                            },
                        },
                        labelLine: {
                            length: this.graphicRatio(12),
                            length2: this.graphicRatio(25),
                            maxSurfaceAngle: this.graphicRatio(80)
                        },
                        // data: data
                        data: value1
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //教师应用环比
        adhibition_compare(currentYearName, currentYearValue, lastYearName, lastYearValue, type1, type2) {
            this.$echarts.init(document.getElementById("adhibition_compare")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("adhibition_compare"));
            myChart.setOption({
                legend: {
                    show: true,
                    top: '5%',
                    left: 'center',
                    itemWidth: this.graphicRatio(20),
                    itemHeight: this.graphicRatio(11),
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    }
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },

                },
                grid: [{
                    show: false,
                    top: '20%',
                    bottom: '8%',
                    height: '31%'
                },
                {
                    show: false,
                    top: '51%',
                    bottom: '8%',
                    height: '0%'
                },
                {
                    show: false,
                    bottom: '8%',
                    containLabel: false,
                    height: '31%'
                }
                ],
                xAxis: [{
                    type: 'category',
                    data: currentYearName,
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        margin: this.graphicRatio(12),
                        interval: 0,
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                },
                {
                    type: 'category',
                    gridIndex: 1,
                    data: [],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        interval: 0,
                    },
                    splitLine: {
                        show: false
                    }
                },

                {
                    data: lastYearName,
                    gridIndex: 2,
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        inside: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        interval: 0,
                    },
                    splitLine: {
                        show: false
                    }
                }
                ],
                yAxis: [{
                    type: 'value',
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // 轴线的颜色
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false // 隐藏刻度线
                    },
                    splitLine: {
                        show: false,

                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    inverse: true,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    inverse: true,
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(12),
                    },
                }
                ],
                series: [{
                    name: type1,
                    type: 'bar',
                    barWidth: this.graphicRatio(20),
                    stack: '1',
                    data: currentYearValue,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(26, 138, 232, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(98, 230, 238, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff', // 轴线的颜色
                        fontSize: this.graphicRatio(12),
                        formatter: function (params) {
                            return params.value + '次';
                        }
                    }
                },
                {
                    name: '',
                    type: 'bar',
                    barWidth: this.graphicRatio(20),
                    stack: '1',
                    data: []
                },
                {
                    name: type2,
                    type: 'bar',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    barWidth: this.graphicRatio(20),
                    stack: '2',
                    data: lastYearValue,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(236, 237, 112, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(1, 181, 140, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [0, 0, this.graphicRatio(5), this.graphicRatio(5)]
                    },
                    label: {
                        show: true,
                        position: 'bottom',
                        color: '#fff', // 轴线的颜色
                        fontSize: this.graphicRatio(12),
                        formatter: function (params) {
                            return params.value + '次';
                        }
                    }
                    // label: {
                    //     show: true,
                    //     position: 'bottom',
                    //     color: "#CECECE"
                    // }
                }
                ]

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //应用时间分布
        temporal_distribution(value) {
            this.$echarts.init(document.getElementById("temporal_distribution")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("temporal_distribution"));
            myChart.setOption({
                color: ['#8e4ebd', '#6bb1d8', '#2eae9c', '#66a85b', '#c55c94', '#ddb742',],
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    itemWidth: this.graphicRatio(20),
                    top: '4%',
                    itemHeight: this.graphicRatio(2),
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    }
                },
                grid: {
                    top: '18%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#143c6d',
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: "#143c6d"
                        }
                    },

                    // boundaryGap: false,
                    data: ['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时', '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时']
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: "#143c6d"
                        }
                    },

                },
                series: [
                    {
                        name: '一年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'dashed',
                            width: this.graphicRatio(1),
                        },
                        data: value[1]
                    },
                    {
                        name: '二年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'dashed',
                            width: this.graphicRatio(1),
                        },
                        data: value[2]
                    },
                    {
                        name: '三年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'solid',
                            width: this.graphicRatio(1),
                        },
                        data: value[3]
                    },
                    {
                        name: '四年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'dashed',
                            width: this.graphicRatio(1),
                        },
                        data: value[4]
                    },
                    {
                        name: '五年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'dashed',
                            width: this.graphicRatio(1),
                        },
                        data: value[5]
                    },
                    {
                        name: '六年级',
                        type: 'line',
                        showSymbol: false,
                        lineStyle: {
                            type: 'solid',
                            width: this.graphicRatio(1),
                        },
                        data: value[6]
                    }
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //上课分布统计
        distribution_statistics(value) {
            this.$echarts.init(document.getElementById("distribution_statistics")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("distribution_statistics"));
            // console.log('value', value);
            const series = Object.entries(value).map(item =>
            ({
                name: item[0],
                type: 'bar',
                barWidth: this.graphicRatio(18),
                itemStyle: {
                    borderRadius: [this.graphicRatio(7), this.graphicRatio(7), 0, 0],
                },
                data: item[1],
                label: {
                    show: true,
                    position: 'top',
                    color: '#fff', // 轴线的颜色
                    fontSize: this.graphicRatio(12),
                    formatter: function (params) {
                        return params.value + '节';
                    }
                }
            }));
            // console.log('series', series);
            let color = [
                new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [{
                        offset: 0,
                        color: 'rgba(254, 166, 190,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(255, 179, 243,1)'
                    }]),
                new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [{
                        offset: 0,
                        color: 'rgba(100, 190, 253,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(98, 237, 217,1)'
                    }]),
                new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [{
                        offset: 0,
                        color: 'rgba(254, 106, 143,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(250, 193, 141,1)'
                    }]),
                new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [{
                        offset: 0,
                        color: 'rgba(82, 219, 209,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(142, 230, 150,1)'
                    }]),
                new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                    [{
                        offset: 0,
                        color: 'rgba(10, 243, 227,1)'
                    }, {
                        offset: 1,
                        color: 'rgba(11, 135, 147,1)'
                    }]),
            ]
            myChart.setOption({
                color: color,
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                legend: {
                    show: true,
                    right: '3%',
                    itemWidth: this.graphicRatio(12),
                    itemHeight: this.graphicRatio(12),
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: {
                    top: '19%',
                    left: '1%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#258bd6'
                            }
                        },
                        data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                        // data: ['一年级', '二年级', '三年级'],
                        axisTick: {
                            show: false,
                            alignWithLabel: true
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                        },
                    }
                ],
                yAxis: [
                    {
                        show: false,
                        type: 'value'
                    }
                ],
                series: series
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //请求集合
        gather() {
            //本校应用整体分析
            this.getoverallAnalysis();
            //本校上课类型分布
            this.gettakeClassTypeDistribution();
            //教师应用环比
            this.getteachApplyCountRatio();
            //应用时间分布
            this.getschoolTimeDistribution();
            //上课分布统计
            this.gettakeClassTypeDistributionGroupGrade();
            //各类课程应用详情
            this.getdetailsCourseApply();
            //教师应用详情
            this.getteacherApplyDetails();
        },
        //接收数据参数
        receive(value1, value2) {
            this.termKey = value1;
            this.school_Id = value2;
            this.gather()
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.receive(this.termKey_value, this.schoolId);
    },
}
</script>
<style lang="less" scoped>
@import "./less/schoolData_teaUse.less";
</style>